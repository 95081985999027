import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "./pages/Home Page/HomePage";
import Adresses from "./pages/Adresses/Adresses";
import Inscriptions from "./pages/Inscriptions/Inscriptions";
import Calendrier from "./pages/Calendrier/Calendrier";
import Notes from "./pages/Notes/Notes";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={HomePage}/>
                <Route path="/adresses" exact component={Adresses}/>
                <Route path="/inscriptions" exact component={Inscriptions}/>
                <Route path="/calendar" exact component={Calendrier}/>
                <Route path="/notes" exact component={Notes}/>

            </Switch>
        </Router>
    );
}

export default App;
