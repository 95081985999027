import React from "react";
import cross from "../assets/cross.svg";
import Modal from "react-modal";
import "./_modalInfos.scss";

export default function ModalInfo({
  modalInfo,
  setModalInfo,
  infos,
  title,
  isAide,
  isScola,
}) {
  return (
    <Modal
      isOpen={modalInfo}
      className={"modalInfos"}
      overlayClassName="OverlayModal"
      closeTimeoutMS={300}
    >
      <div className={"contentModalInfos"}>
        <div className={"headerModalInfos"}>
          <p>{title}</p>

          <img
            src={cross}
            alt=""
            className={"cross hover"}
            onClick={() => setModalInfo(false)}
          />
        </div>

        {infos && <p className={"infos"}>{infos}</p>}

        {isAide && (
          <div style={{ color: "#3d3d3d" }}>
            <p className={"infos"} style={{ marginBottom: 15 }}>
              Ce canal vous permet de consulter votre dossier Etudiant. En
              accédant à votre dossier, vous trouverez les informations
              suivantes :
            </p>

            <li>
              <ul>
                Etat-civil : Nom, prénoms, date de naissance, numéro de dossier,
                baccalauréat...
              </ul>
            </li>

            <li>
              <ul>Adresses : Adresse annuelle, Adresse fixe</ul>
            </li>

            <li>
              <ul>
                Inscriptions : La liste de toutes vos inscriptions à
                l'Université...
              </ul>
            </li>

            <li>
              <ul>Calendrier des examens : Dates, Salles, Durées...</ul>
            </li>

            <li>
              <ul>
                Notes et résultats : Tous vos résultats aux diplômes, années
                intermédiaires, éléments pédagogiques et épreuves.
              </ul>
            </li>

            <p className={"infos"} style={{ marginTop: 30 }}>
              Remarque : Les résultats sont visibles dès leur publication par
              les scolarités.
            </p>
          </div>
        )}

        {isScola && (
          <p className={"infos"}>
            Suite à un défaut technique, vos certificats de scolarité ne sont
            pas disponibles en ligne. Veuillez contacter la scolarité de votre
            filière pour en obtenir une copie.
          </p>
        )}
      </div>
    </Modal>
  );
}
