import React from "react";
import './../_homePage.scss'
import Menu from "../../components/Menu";
import {TitlePage, WidgetClassic} from "../../components/MainComponents";
import {widgetAdresse} from "../../Data";

export default function Adresses() {

    return (
        <div className={'page'}>
            <Menu pageActive={'adresses'}/>

            <div className={'contentHomePage'}>
                <TitlePage title={'Adresses'}/>

                <div style={{display: 'flex', width: 'calc(100vw - 254px)', gap: 10, alignItems: 'flex-start'}}>
                    <WidgetClassic little={true} titleWidget={'Adresse annuelle 2023/2024'} dataTable={widgetAdresse}/>
                    <WidgetClassic little={true} titleWidget={'Adresse fixe'} dataTable={widgetAdresse}/>
                </div>
            </div>
        </div>
    );
}
