import React from "react";
import Menu from "../../components/Menu";
import {TitlePage, WidgetClassic} from "../../components/MainComponents";

export default function Calendrier() {

    return(
        <div className={'page'}>
            <Menu pageActive={'calendar'}/>
            <div className={'contentHomePage'}>
                <TitlePage title={'Calendrier des épreuves'}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'calc(100vw - 254px)',
                    gap: 10,
                    alignItems: 'flex-start'
                }}>
                    <WidgetClassic titleWidget={'Calendrier'}
                                   liteInfo={'Calendrier des épreuves planifiées par votre service scolarité, attention certaines épreuves (oraux, devoirs etc) peuvent ne pas apparaître dans ce calendrier.'} noHeader={true}/>

                    <WidgetClassic titleWidget={'Calendrier'}
                                   info={'Pas de calendrier disponible'}/>

                </div>
            </div>
        </div>
    )
}
