import React from "react";
import '../_homePage.scss'
import Menu from "../../components/Menu";
import {TitlePage, WidgetClassic} from "../../components/MainComponents";
import {widgetBac, widgetContact, widgetGeneral} from "../../Data";

export default function HomePage() {

    return (
        <div className={'page'}>
            <Menu pageActive={'home'}/>

            <div className={'contentHomePage'}>
                <TitlePage title={'Etat-civil'}/>

                <div style={{display: 'flex', width: 'calc(100vw - 254px)', gap: 10, alignItems: 'flex-start'}}>
                    <WidgetClassic titleWidget={'Généralités'} dataTable={widgetGeneral}/>
                    <WidgetClassic titleWidget={'Baccalauréat'} dataTable={widgetBac}/>
                </div>
                <WidgetClassic titleWidget={'Contact'} edit={true} dataTable={widgetContact}/>
            </div>
        </div>
    );
}
