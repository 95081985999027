import React from "react";

export const widgetGeneral = [
  {
    name: "Dossier",
    value: "22002050",
  },
  {
    name: "NNE",
    value: "060988645AJ",
  },
  {
    name: "Prénom et Nom",
    value: "LOUIS RICHARD",
  },
  {
    name: "Email",
    value: "louis.richard@ens.uvsq.fr",
  },
  {
    name: "Nationalité",
    value: "FRANCAIS(E)",
  },
  {
    name: "Date de naissance",
    value: "03/12/2002",
  },
  {
    name: "À",
    value: "VERSAILLES",
  },
  {
    name: "Département ou Pays",
    value: "YVELINES",
  },
];

export const widgetBac = [
  {
    name: "Baccalauréat",
    value: "Littéraire",
  },
  {
    name: "Année",
    value: "2020",
  },
  {
    name: "Mention",
    value: "Assez Bien",
  },
  {
    name: "Type établissement",
    value: "",
  },
  {
    name: "Etablissement",
    value: "",
  },
  {
    name: "Département",
    value: "YVELINES",
  },
];

export const widgetContact = [
  {
    name: "Portable",
    value: "33769094854",
  },
  {
    name: "Email personnel",
    value: "louisrichard.law@icloud.com",
  },
];

export const widgetAdresse = [
  {
    name: "Adresse",
    value: "3 AVENUE DU GÉNÉRAL MANGIN",
  },
  {
    name: "Ville",
    value: "78000 VERSAILLES",
  },
  {
    name: "Pays",
    value: "FRANCE",
  },
  {
    name: "Téléphone",
    value: "+33769094854",
  },
];

export const widgetPremiereInscription = [
  {
    name: "Année",
    value: "2020",
  },
  {
    name: "Etablissement",
    value: "UNIVERSITE DE VERSAILLES SAINT-QUENTIN",
  },
];

export const widgetInscriptions = [
  {
    icon: false,
    value1: "Année",
    value2: "Composante",
    value3: "Filière d'inscription",
    color: "#5560A4",
  },
  {
    icon: true,
    value1: "2023/2024",
    value2: "UFR de Droit et Science Politique",
    value3: "L3 Droit",
  },
  {
    icon: true,
    value1: "2022/2023",
    value2: "UFR de Droit et Science Politique",
    value3: "L2 Droit",
  },
  {
    icon: true,
    value1: "2021/2022",
    value2: "UFR de Droit et Science Politique",
    value3: "L2 Droit",
  },
  {
    icon: true,
    value1: "2020/2021",
    value2: "UFR de Droit et Science Politique",
    value3: "L1 Droit",
  },
];

export const widgetDiplomes = [
  {
    value1: "Année",
    value2: "Code",
    value3: "Diplôme",
    value4: "Session",
    value5: "Notes",
    value6: "Résultat",
    value7: "Mention",
    value8: "Rang",
    color: "#5560A4",
  },
  {
    value1: "2023/2024",
    value2: "LICDEG",
    value3: "3ème année Licence Droit",
    value4: "",
    value5: "",
    value6: "ADM",
    value7: "",
    value8: "",
  },
  {
    value1: "2022/2023",
    value2: "LICDEG",
    value3: "1ère ou 2ème année Licence Droit",
    value4: "",
    value5: "",
    value6: "ADM",
    value7: "",
    value8: "",
  },
  {
    value1: "2021/2022",
    value2: "LICDEG",
    value3: "1ère ou 2ème année Licence Droit",
    value4: "",
    value5: "",
    value6: "AJ",
    value7: "",
    value8: "",
  },
  {
    value1: "2020/2021",
    value2: "LICDEG",
    value3: "1ère ou 2ème année Licence Droit",
    value4: "",
    value5: "",
    value6: "ADM",
    value7: "",
    value8: "",
  },
];

export const widgetFilieresInscriptions = [
  {
    value1: "Année",
    value2: "Code",
    value3: "Filière d'inscription",
    value4: "Session",
    value5: "Notes",
    value6: "Résultat",
    value7: "Mention",
    value8: "Rang",
    color: "#5560A4",
  },
  {
    value1: "2023/2024",
    value2: "LDROI3/20",
    value3: "L3 Droit",
    value4: "",
    value5: "",
    value6: "ADM",
    value7: "",
    value8: "",
    clickable: true,
    year: "quatriemeAnnee",
  },
  {
    value1: "2022/2023",
    value2: "LDROI2/20",
    value3: "L2 Droit",
    value4: "",
    value5: "",
    value6: "ADM",
    value7: "",
    value8: "",
    clickable: true,
    year: "troisiemeAnnee",
  },
  {
    value1: "2021/2022",
    value2: "LDROI2/20",
    value3: "L2 Droit",
    value4: "Session 2",
    value5: "AJ",
    value6: "AJ",
    value7: "",
    value8: "",
    clickable: true,
    year: "deuxiemeAnnee",
  },
  {
    value1: "2020/2021",
    value2: "LDROI1/20",
    value3: "L1 Droit",
    value4: "",
    value5: "",
    value6: "ADM",
    value7: "",
    value8: "",
    clickable: true,
    year: "premiereAnnee",
  },
];

export const premiereAnnee = [
  {
    value1: "Code",
    value2: "Libellé",
    value3: "Note",
    value4: "Résultat",
    value5: "Rang",
    value6: "ECTS",
    color: "#5560A4",
  },
  {
    value1: "LDROI1",
    value2: "L1 Droit",
    value3: "11.58/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    isStrong: true,
  },
  {
    value1: "L1DTSEM1",
    value2: "Licence 1 Parcours droit Semestre 1",
    value3: "12.696/20",
    value4: "ADM",
    value5: " ",
    value6: "28/28",
    isStrong: true,
  },
  {
    value1: "BCCAPDT1",
    value2: "BCC DISCIPLINAIRE COMPLEMENTAIRE D' APPUI",
    value3: "12.6/20",
    value4: "ADM",
    value5: " ",
    value6: "5/5",
    gap: 1,
  },
  {
    value1: "LDRT19B",
    value2: "Histoire Politique de la France",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 3,
  },
  {
    value1: "LDRT13P1",
    value2: "Introduction à la science politique",
    value3: "13/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 2,
  },
  {
    value1: "BCCFDTS1",
    value2: "BCC DISCIPLINAIRE FONDAMENTAL OBLIGATOIRE DROIT S1",
    value3: "12.964/20",
    value4: "ADM",
    value5: " ",
    value6: "21/21",
    gap: 1,
  },
  {
    value1: "LDRT03",
    value2: "Institutions administratives et judiciaires",
    value3: "16.25/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 2,
  },
  {
    value1: "LDRT10",
    value2: "Introduction Général au droit",
    value3: "14.5/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRT11",
    value2: "Droit constitutionnel 1",
    value3: "11.25/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRT12",
    value2: "Histoire du droit 1 avec TD",
    value3: "11.5/20",
    value4: "ADM",
    value5: " ",
    value6: "6/6",
    gap: 2,
  },
  {
    value1: "BCCLINS1",
    value2: " BCC LINGUISTIQUE S1",
    value3: "10.117/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 1,
  },
  {
    value1: "ANGS1",
    value2: " BCC LINGUISTIQUE S1",
    value3: "10.117/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 2,
  },
  {
    value1: "L1DTSEM2",
    value2: "Licence 1 Parcours droit Semestre 1",
    value3: "10.349/20",
    value4: "ADM",
    value5: " ",
    value6: "29/29",
    isStrong: true,
  },
  {
    value1: "BCCAPRS2",
    value2: "BBC disciplinaire 2 : approfondissement",
    value3: "10.631/20",
    value4: "ADM",
    value5: " ",
    value6: "21/21",
    gap: 1,
  },
  {
    value1: "LDRT05P1",
    value2: " Institutions internationales et européennes ss TD",
    value3: "7.42/20",
    value4: "ADM",
    value5: " ",
    value6: "0/3",
    gap: 2,
  },
  {
    value1: "LDRT20P",
    value2: "Droit civil : Droit des personnes et de la famille",
    value3: "10.5/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRT21P",
    value2: "Droit constitutionnel 2",
    value3: "10.5/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRT22P",
    value2: "Histoire du droit 2",
    value3: "12.5/20",
    value4: "ADM",
    value5: " ",
    value6: "6/6",
    gap: 2,
  },
  {
    value1: "BCCCOMP2",
    value2: "BCC Disciplinaire complémentaire d'appui S2",
    value3: "11.445/20",
    value4: "ADM",
    value5: " ",
    value6: "6/6",
    gap: 1,
  },
  {
    value1: "LDRT24P1",
    value2: "Idées politiques",
    value3: "10.89/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRTGEO",
    value2: "Géopolitique",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "BCCLING2",
    value2: "BCC LINGUISTIQUE S2",
    value3: "4.1/20",
    value4: "AJ",
    value5: " ",
    value6: "0/2",
    gap: 1,
  },
  {
    value1: "ANGS2",
    value2: "Anglais Semestre 2",
    value3: "4.1/20",
    value4: "AJ",
    value5: " ",
    value6: "0/2",
    gap: 2,
  },
];

export const deuxiemeAnnee = [
  {
    value1: "Code",
    value2: "Libellé",
    value3: "Note",
    value4: "Résultat",
    value5: "Rang",
    value6: "ECTS",
    color: "#5560A4",
  },
  {
    value1: "LDROI2",
    value2: "L2 Droit",
    value3: "AJ",
    value4: "AJ",
    value5: " ",
    value6: " ",
    isStrong: true,
  },
  {
    value1: "L2DRTS3",
    value2: "Semestre 3 Parcours Droit",
    value3: "AJ",
    value4: "AJ",
    value5: " ",
    value6: "0/29",
    isStrong: true,
  },
  {
    value1: "BCC153",
    value2: "BCC Obligatoire Parcours Droit S3",
    value3: "11.75",
    value4: "ADM",
    value5: " ",
    value6: "20/20",
    gap: 1,
  },
  {
    value1: "LDRT30P1",
    value2: "Droit civil des obligations",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 2,
  },
  {
    value1: "LDRT30P1CC",
    value2: "droit civil des obligations",
    value3: "13/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT3OP1EX",
    value2: "droit civil des obligations S3",
    value3: "11/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT31P1",
    value2: "Droit administratif 1",
    value3: "10.25/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 2,
  },
  {
    value1: "LDRT31P1CC",
    value2: "droit administratif",
    value3: "10.5/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT31P1EX",
    value2: "droit administratif S3",
    value3: "10/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT32P1",
    value2: "Droit commercial",
    value3: "8/20",
    value4: "AJ",
    value5: " ",
    value6: "0/3",
    gap: 2,
  },
  {
    value1: "LDRT55P1",
    value2: "Droit public financier",
    value3: "7/20",
    value4: "AJ",
    value5: " ",
    value6: "0/3",
    gap: 2,
  },
  {
    value1: "BCC2S3",
    value2: "1UE Obligaroire et 1UE à choix 53 Droit",
    value3: "AJ",
    value4: "AJ",
    value5: " ",
    value6: "0/7",
    gap: 1,
  },
  {
    value1: "LDRT53",
    value2: "Politiques Economiques",
    value3: "11.5/20",
    value4: "AJ",
    value5: " ",
    value6: "0/3",
    gap: 3,
  },
  {
    value1: "LDRT73",
    value2: "Sources Fondamentaux du droit",
    value3: "8/20",
    value4: "AJ",
    value5: " ",
    value6: "0/4",
    gap: 2,
  },
  {
    value1: "BCCLANG3",
    value2: "BCC LANGUES S3",
    value3: "7.5/20",
    value4: "AJ",
    value5: " ",
    value6: "0/2",
    gap: 2,
  },
  {
    value1: "LDROANG3",
    value2: "Anglais semestre 3 Licence 2",
    value3: "7.5/20",
    value4: "AJ",
    value5: " ",
    value6: "0/3",
    gap: 3,
  },
  {
    value1: "L2DRTS4",
    value2: "Semestre 4 parcours Droit",
    value3: "AJ",
    value4: "AJ",
    value5: " ",
    value6: "0/29",
    isStrong: true,
  },
  {
    value1: "BCC154",
    value2: "3UE OBLIGATOIRES DROIT",
    value3: "AJ",
    value4: "AJ",
    value5: " ",
    value6: "0/21",
    gap: 1,
  },
  {
    value1: "LDRT40P1",
    value2: "Droit civil des obligations",
    value3: "13/20",
    value4: "ADM",
    value5: " ",
    value6: "0/7",
    gap: 2,
  },
  {
    value1: "LDRT40P1CC",
    value2: "droit civil des obligations",
    value3: "12/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LORT4OP1EX",
    value2: "droit civil des obligations s4",
    value3: "14/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT41P1|",
    value2: "Droit administratif 2",
    value3: "AJ",
    value4: "AJ",
    value5: " ",
    value6: "0/7",
    gap: 2,
  },
  {
    value1: "LDRT41P1CC",
    value2: "droit administratif",
    value3: "7/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT41P1EX",
    value2: "droit administratif 2 54",
    value3: "8/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT42P1",
    value2: "Droit pénal",
    value3: "AJ",
    value4: "AJ",
    value5: " ",
    value6: "0/7",
    gap: 2,
  },
  {
    value1: "LDRT42P1CC",
    value2: "droit pénal",
    value3: "11/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "LDRT42P1EX",
    value2: "droit pénal",
    value3: "8/20",
    value4: " ",
    value5: " ",
    value6: " ",
    gap: 3,
  },
  {
    value1: "BCC2S4",
    value2: "1UE OBL et 1UE à choix",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: "6/6",
    gap: 1,
  },
  {
    value1: "LDRT64P1",
    value2: "Sociologie des partis politiques",
    value3: "13.5/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 3,
  },
  {
    value1: "LDRT71P1",
    value2: "Histoire du droit pénal et de la justice criminelle",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 2,
  },
  {
    value1: "BCCLANG4|",
    value2: "1UE de Langue à choix",
    value3: "11/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 1,
  },
  {
    value1: "LDROANG4",
    value2: "Anglais semestre 4 Licence 2",
    value3: "11/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 1,
  },
];

export const troisiemeAnnee = [
  {
    value1: "Code",
    value2: "Libellé",
    value3: "Note",
    value4: "Résultat",
    value5: "Rang",
    value6: "ECTS",
    color: "#5560A4",
  },
  {
    value1: "LDROI2",
    value2: "L2 Droit",
    value3: " ",
    value4: "ADM",
    value5: " ",
    value6: " ",
    isStrong: true,
  },
  {
    value1: "L2DRTS3",
    value2: "Semestre 3 Parcours Droit",
    value3: "12.25/20",
    value4: "ADM",
    value5: " ",
    value6: "29/29",
    isStrong: true,
  },
  {
    value1: "BCC153",
    value2: "BCC Obligatoire Parcours Droit S3",
    value3: "11.75/20",
    value4: "ADM",
    value5: " ",
    value6: "20/20",
    gap: 1,
  },
  {
    value1: "LDRT30P1",
    value2: "Droit civil des obligations",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 2,
  },

  {
    value1: "LDRT31P1",
    value2: "Droit administratif 1",
    value3: "10.25/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 2,
  },
  {
    value1: "LDRT32P1",
    value2: "Droit commercial",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 2,
  },
  {
    value1: "LDRT55P1",
    value2: "Droit public financier",
    value3: "14/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 2,
  },
  {
    value1: "BCC2S3",
    value2: "1UE Obligaroire et 1UE à choix 53 Droit",
    value3: "12/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 1,
  },
  {
    value1: "LDRT53",
    value2: "Politiques Economiques",
    value3: "11.5/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 3,
  },
  {
    value1: "LDRT73",
    value2: "Sources Fondamentaux du droit",
    value3: "12.25/20",
    value4: "ADM",
    value5: " ",
    value6: "4/4",
    gap: 2,
  },
  {
    value1: "BCCLANG3",
    value2: "BCC LANGUES S3",
    value3: "14.25/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 2,
  },
  {
    value1: "LDROANG3",
    value2: "Anglais semestre 3 Licence 2",
    value3: "14.25/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 3,
  },
  {
    value1: "L2DRTS4",
    value2: "Semestre 4 parcours Droit",
    value3: "11.75/20",
    value4: "ADM",
    value5: " ",
    value6: "29/29",
    isStrong: true,
  },
  {
    value1: "BCC154",
    value2: "3UE OBLIGATOIRES DROIT",
    value3: "13/20",
    value4: "ADM",
    value5: " ",
    value6: "21/21",
    gap: 1,
  },
  {
    value1: "LDRT40P1",
    value2: "Droit civil des obligations",
    value3: "13.5/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 2,
  },

  {
    value1: "LDRT41P1|",
    value2: "Droit administratif 2",
    value3: "9.5/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 2,
  },

  {
    value1: "LDRT42P1",
    value2: "Droit pénal",
    value3: "14/20",
    value4: "ADM",
    value5: " ",
    value6: "7/7",
    gap: 2,
  },

  {
    value1: "BCC2S4",
    value2: "1UE OBL et 1UE à choix",
    value3: "10/20",
    value4: "ADM",
    value5: " ",
    value6: "6/6",
    gap: 1,
  },
  {
    value1: "LDRT64P1",
    value2: "Sociologie des partis politiques",
    value3: "8.5/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 3,
  },
  {
    value1: "LDRT71P1",
    value2: "Histoire du droit pénal et de la justice criminelle",
    value3: "11.5/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 2,
  },
  {
    value1: "BCCLANG4|",
    value2: "1UE de Langue à choix",
    value3: "11/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 1,
  },
  {
    value1: "LDROANG4",
    value2: "Anglais semestre 4 Licence 2",
    value3: "11/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 1,
  },
];

export const quatriemeAnnee = [
  {
    value1: "Code",
    value2: "Libellé",
    value3: "Note",
    value4: "Résultat",
    value5: "Rang",
    value6: "ECTS",
    color: "#5560A4",
  },
  {
    value1: "LDROI3PRIV",
    value2: "L3 Droit - Parcours droit privé",
    value3: "11.06/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    isStrong: true,
  },
  {
    value1: "L3DTSEM5",
    value2: "Licence 3 Parcours droit privé Semestre 5",
    value3: "9.86/20",
    value4: "ADM",
    value5: " ",
    value6: "28/28",
    isStrong: true,
  },
  {
    value1: "BCCAPDT1",
    value2: "BCC DISCIPLINAIRE COMPLEMENTAIRE D'APPUI",
    value3: "7.6/20",
    value4: "AJ",
    value5: " ",
    value6: "0/5",
    gap: 1,
  },
  {
    value1: "LDRT19B",
    value2: "Politiques publiques",
    value3: "7/20",
    value4: "AJ",
    value5: " ",
    value6: "0/2",
    gap: 2,
  },
  {
    value1: "LDRT13P1",
    value2: "Droit institutionnel de l'Union européenne",
    value3: "8/20",
    value4: "AJ",
    value5: " ",
    value6: "0/3",
    gap: 2,
  },
  {
    value1: "BCCFDTS5",
    value2: "BCC DISCIPLINAIRE FONDAMENTAL OBLIGATOIRE DROIT S5",
    value3: "10.25/20",
    value4: "AJ",
    value5: " ",
    value6: "18/21",
    gap: 1,
  },
  {
    value1: "LDRT03",
    value2: "Procédure pénale",
    value3: "7/20",
    value4: "ADM",
    value5: " ",
    value6: "0/3",
    gap: 2,
  },
  {
    value1: "LDRT10",
    value2: "Procédure civile",
    value3: "10.75/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRT11",
    value2: "Régime général des obligations",
    value3: "11.5/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRT12",
    value2: "Droit des biens",
    value3: "12.5/20",
    value4: "ADM",
    value5: " ",
    value6: "6/6",
    gap: 2,
  },
  {
    value1: "BCCLINS5",
    value2: "BCC LINGUISTIQUE S5",
    value3: "11.5/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 1,
  },
  {
    value1: "ANGS5",
    value2: "Anglais S5",
    value3: "11.5/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 2,
  },
  {
    value1: "L3DTSEM6",
    value2: "Licence 3 Parcours droit privé Semestre 6",
    value3: "12.23/20",
    value4: "ADM",
    value5: " ",
    value6: "29/29",
    isStrong: true,
  },
  {
    value1: "BCCAPRS6",
    value2: "BCC disciplinaire 6 : approfondissement",
    value3: "11.20/20",
    value4: "ADM",
    value5: " ",
    value6: "21/21",
    gap: 1,
  },
  {
    value1: "LDRTO5P1",
    value2: "Droit des sociétés",
    value3: "12.9/20",
    value4: "ADM",
    value5: " ",
    value6: "3/3",
    gap: 2,
  },
  {
    value1: "LDRT20P",
    value2: "Droit social",
    value3: "9.5/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "BCCCOMP2",
    value2: "BCC Disciplinaire complémentaire d'appui S6",
    value3: "12.9/20",
    value4: "ADM",
    value5: " ",
    value6: "21/21",
    gap: 1,
  },
  {
    value1: "LDRT24P2",
    value2: "Histoire du droit privé",
    value3: "13.3/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRTCS1",
    value2: "Droit des contrats spéciaux",
    value3: "14.4/20",
    value4: "ADM",
    value5: " ",
    value6: " ",
    gap: 2,
  },
  {
    value1: "LDRTPD2",
    value2: "Philosophie du droit",
    value3: "11/20",
    value4: "ADM",
    value5: " ",
    value6: "5/5",
    gap: 2,
  },
  {
    value1: "BCCLING2",
    value2: "BCC LINGUISTIQUE S6",
    value3: "13.21/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 1,
  },
  {
    value1: "ANGS2",
    value2: "Anglais S6",
    value3: "13.21/20",
    value4: "ADM",
    value5: " ",
    value6: "2/2",
    gap: 2,
  },
];
