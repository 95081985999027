import React, { useState } from "react";
import "./_menu.scss";
import home from "../assets/home.svg";
import people from "../assets/people.svg";
import calendar from "../assets/calendar.svg";
import doc from "../assets/doc.svg";
import list from "../assets/list.svg";
import help from "../assets/help.svg";
import logOut from "../assets/logOut.svg";
import { NavLink } from "react-router-dom";
import ModalInfo from "./ModalInfo";

export default function Menu({ pageActive }) {
  const [modalInfo, setModalInfo] = useState(false);

  function ItemMenu({ icon, name, path, isActive, isAide }) {
    if (isAide) {
      return (
        <div
          className={isActive ? "itemMenuActive" : "itemMenu"}
          onClick={() => setModalInfo(true)}
        >
          <img src={icon} alt="" className={"icon"} />
          {name}
        </div>
      );
    } else {
      return (
        <NavLink exact to={path}>
          <div className={isActive ? "itemMenuActive" : "itemMenu"}>
            <img src={icon} alt="" className={"icon"} />
            {name}
          </div>
        </NavLink>
      );
    }
  }

  return (
    <div className={"menu"}>
      <p className={"nameNumber"}>LOUIS RICHARD</p>
      <p className={"nameNumber"} style={{ marginBottom: 8 }}>
        22002050
      </p>
      <div className={"bar"} />

      <ItemMenu
        name={"Etat-civil"}
        isActive={pageActive === "home"}
        icon={home}
        path={"/"}
      />
      <ItemMenu
        name={"Adresses"}
        isActive={pageActive === "adresses"}
        icon={people}
        path={"/adresses"}
      />
      <ItemMenu
        name={"Inscriptions"}
        isActive={pageActive === "inscriptions"}
        icon={doc}
        path={"/inscriptions"}
      />
      <ItemMenu
        name={"Calendrier des épreuves"}
        isActive={pageActive === "calendar"}
        icon={calendar}
        path={"/calendar"}
      />
      <ItemMenu
        name={"Notes et résultats"}
        icon={list}
        isActive={pageActive === "notes"}
        path={"/notes"}
      />

      <div className={"bar"} />

      <ItemMenu name={"Aide"} icon={help} isAide={true} />
      <ItemMenu name={"Déconnexion"} icon={logOut} path={"/inscriptions"} />

      <div className={"bar"} />

      <ModalInfo
        title={"Aide"}
        setModalInfo={setModalInfo}
        modalInfo={modalInfo}
        isAide={true}
      />
    </div>
  );
}
