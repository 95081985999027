import React from "react";
import Menu from "../../components/Menu";
import {TitlePage, WidgetClassic} from "../../components/MainComponents";
import {widgetDiplomes, widgetFilieresInscriptions} from "../../Data";

export default function Notes() {

    return(
        <div className={'page'}>
            <Menu pageActive={'notes'}/>
            <div className={'contentHomePage'}>
                <TitlePage title={'Notes & résultats'}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'calc(100vw - 254px)',
                    gap: 10,
                    alignItems: 'flex-start'
                }}>
                    <WidgetClassic titleWidget={'Diplômes'}
                                   dataTable={widgetDiplomes} multiTable={true}/>

                    <WidgetClassic titleWidget={'UNIVERSITÉ DE VERSAILLES SAINT-QUENTIN-EN-YVELINES'}
                                   dataTable={widgetFilieresInscriptions} multiTable={true}/>

                    <WidgetClassic titleWidget={'Signification des codes résultats'}
                                  liteInfo={'DEF : Non Admissible ou Défaillant'} />

                </div>
            </div>
        </div>
    )
}
