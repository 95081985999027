import React from "react";
import Modal from "react-modal";
import "./_modalNotes.scss";
import cross from "../../assets/cross.svg";
import { WidgetClassic } from "../../components/MainComponents";
import {
  widgetFilieresInscriptions,
  premiereAnnee,
  quatriemeAnnee,
  deuxiemeAnnee,
  troisiemeAnnee,
} from "../../Data";
export default function ModalNotes({
  modalNotes,
  setModalNotes,
  year,
  setYear,
}) {
  return (
    <Modal
      isOpen={modalNotes}
      className={"modalNotes"}
      overlayClassName="OverlayModal"
      closeTimeoutMS={300}
    >
      <img
        src={cross}
        alt=""
        className={"cross hover"}
        onClick={() => setModalNotes(false)}
      />

      <p className={"titleModalNotes"}>Détail des notes</p>
      <p className={"subtitleModalNotes"}>
        Notes données à titre informatif dans l'attente de la validation par le
        jury de diplôme
      </p>

      <WidgetClassic
        titleWidget={
          year === "premiereAnnee"
            ? "L1 Droit - Année universitaire 2020/2021"
            : year === "deuxiemeAnnee"
              ? "L2 Droit - Année universitaire 2021/2022"
              : year === "troisiemeAnnee"
                ? "L2 Droit - Année universitaire 2022/2023"
                : "L3 Droit - Année universitaire 2023/2024"
        }
        dataTable={
          year === "premiereAnnee"
            ? premiereAnnee
            : year === "deuxiemeAnnee"
              ? deuxiemeAnnee
              : year === "troisiemeAnnee"
                ? troisiemeAnnee
                : quatriemeAnnee
        }
        multiTable={true}
        tablesNotes={true}
      />
      <div style={{ height: 15 }} />
      <WidgetClassic
        titleWidget={"Signification des codes résultats"}
        isModalNotes={true}
      />
    </Modal>
  );
}
