import React, { useState } from "react";
import "./_mainComponents.scss";
import pdf from "../assets/pdf.svg";
import ModalNotes from "../pages/Notes/ModalNotes";
import ModalInfo from "./ModalInfo";

export function TitlePage({ title }) {
  return <div className={"titlePage"}>{title}</div>;
}

export function WidgetClassic({
  edit,
  titleWidget,
  dataTable,
  little,
  info,
  table,
  isModalNotes,
  noHeader,
  liteInfo,
  multiTable,
  tablesNotes,
}) {
  const [modalNotes, setModalNotes] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalInfoScola, setModalInfoScola] = useState(false);
  const [year, setYear] = useState("");

  function ItemDataTable({ name, value }) {
    return (
      <div className={"itemDataTable"}>
        <p
          className={"nameItemDataTable"}
          style={{ width: little ? 100 : 162 }}
        >
          {name}
        </p>
        <p className={"valueItemDataTable"}>{value}</p>
      </div>
    );
  }

  function ItemTable({
    index,
    value1,
    value2,
    value3,
    color,
    icon,
    value4,
    value5,
    value6,
    value7,
    value8,
    clickable,
    itemYear,
    isStrong,
    gap,
  }) {
    return (
      <div
        className={"itemTable"}
        style={{
          backgroundColor: color
            ? color
            : tablesNotes && index % 2 === 0
              ? "#F3F3F3"
              : null,
          color: color ? "#FFF" : tablesNotes ? "#484848" : null,
          fontSize: color ? 12 : tablesNotes ? 13 : null,
          fontWeight: color ? 600 : null,
          marginTop: index === 0 ? -3 : null,
        }}
      >
        <div
          className={"tableElem"}
          style={{
            width: tablesNotes ? 200 : 250,
            fontWeight: isStrong && "600",
            height: tablesNotes && 37,
          }}
        >
          {value1}
        </div>
        <div
          onClick={() => {
            if (clickable) {
              setModalNotes(true);
              setModalInfo(true);
              setYear(itemYear);
            } else {
            }
          }}
          className={clickable ? "tableElem : hover" : "tableElem"}
          style={{
            width: multiTable && !tablesNotes ? 250 : "calc(100% - 500px)",
            color: clickable ? "#5560A4" : null,
            textDecoration: clickable ? "underline" : null,
            fontWeight: isStrong && "600",
            height: tablesNotes && 37,
          }}
        >
          <div style={{ width: gap ? gap * 18 : 0 }} />
          {value2}
        </div>
        <div
          className={"tableElem"}
          style={{
            width: multiTable && !tablesNotes ? "calc(100% - 500px)" : 250,
            fontWeight: isStrong && "600",
            height: tablesNotes && 37,
          }}
        >
          {value3}
          {icon && (
            <div onClick={() => setModalInfoScola(true)} className={"hover"}>
              <img src={pdf} alt="" className={"pdf"} />
            </div>
          )}
        </div>
        {value6 && (
          <div
            className={"tableElem"}
            style={{
              width: tablesNotes ? 200 : 250,
              fontWeight: isStrong && "600",
              height: tablesNotes && 37,
            }}
          >
            {value4}
          </div>
        )}
        {value6 && (
          <div
            className={"tableElem"}
            style={{
              width: tablesNotes ? 200 : 250,
              height: tablesNotes && 37,
            }}
          >
            {value5}
          </div>
        )}
        {value6 && (
          <div
            className={"tableElem"}
            style={{
              width: tablesNotes ? 200 : 250,
              height: tablesNotes && 37,
            }}
          >
            {value6}
          </div>
        )}
        {value6 && !tablesNotes && (
          <div
            className={"tableElem"}
            style={{ width: 250, height: tablesNotes && 37 }}
          >
            {value7}
          </div>
        )}
        {value6 && !tablesNotes && (
          <div
            className={"tableElem"}
            style={{ width: 250, height: tablesNotes && 37 }}
          >
            {value8}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={"widgetClassic"}>
      {!noHeader && (
        <div
          className={"headerWidget"}
          style={{ fontSize: (isModalNotes || tablesNotes) && 14 }}
        >
          {titleWidget}
        </div>
      )}

      {table || multiTable ? null : liteInfo ? (
        <div className={"liteInfo"}> {liteInfo} </div>
      ) : null}

      {isModalNotes && (
        <div className={"liteInfo"}>
          <strong>DEF</strong> : Non Admissible ou Défaillant{" "}
          <strong style={{ marginLeft: 5 }}>AJ</strong> : Ajourné{" "}
          <strong style={{ marginLeft: 5 }}>ADM</strong> : Admis
        </div>
      )}

      {table || multiTable ? null : info ? (
        <div className={"info"}>{info}</div>
      ) : (
        dataTable &&
        dataTable.map((item, index) => {
          return (
            <ItemDataTable key={index} name={item.name} value={item.value} />
          );
        })
      )}

      {edit && (
        <div className={"editContainer"}>
          <div className={"editButton"}>Modifier</div>
        </div>
      )}

      {table
        ? dataTable &&
          dataTable.map((item, index) => {
            return (
              <ItemTable
                key={index}
                index={index}
                value1={item.value1}
                value2={item.value2}
                value3={item.value3}
                icon={item.icon}
                color={item.color}
              />
            );
          })
        : null}

      {multiTable
        ? dataTable &&
          dataTable.map((item, index) => {
            return (
              <ItemTable
                key={index}
                index={index}
                value1={item.value1}
                value2={item.value2}
                value3={item.value3}
                value4={item.value4}
                value5={item.value5}
                value6={item.value6}
                value7={item.value7}
                value8={item.value8}
                icon={item.icon}
                color={item.color}
                multiTable={true}
                clickable={item.clickable}
                itemYear={item.year}
                isStrong={item.isStrong}
                gap={item.gap}
              />
            );
          })
        : null}

      <ModalNotes
        modalNotes={modalNotes}
        setModalNotes={setModalNotes}
        year={year}
        setYear={setYear}
      />
      <ModalInfo
        modalInfo={modalInfo}
        setModalInfo={setModalInfo}
        title={"Aide"}
        infos={
          "Les notes sont données à titre informatif dans l'attente de la validation par le jury de diplôme."
        }
      />
      <ModalInfo
        modalInfo={modalInfoScola}
        setModalInfo={setModalInfoScola}
        title={"Aide"}
        isScola={true}
      />
    </div>
  );
}
