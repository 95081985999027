import React from "react";
import './../_homePage.scss'
import Menu from "../../components/Menu";
import {TitlePage, WidgetClassic} from "../../components/MainComponents";
import {widgetInscriptions, widgetPremiereInscription} from "../../Data";

export default function Inscriptions() {

    return (
        <div className={'page'}>
            <Menu pageActive={'inscriptions'}/>

            <div className={'contentHomePage'}>
                <TitlePage title={'Inscriptions'}/>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'calc(100vw - 254px)',
                    gap: 10,
                    alignItems: 'flex-start'
                }}>
                    <WidgetClassic titleWidget={'UNIVERSITÉ DE VERSAILLES SAINT-QUENTIN-EN-YVELINES'}
                                   dataTable={widgetInscriptions} table={true}/>
                    <WidgetClassic titleWidget={'Autres cursus'}
                                   info={'Pas de cursus universitaire effectué en dehors de UNIVERSITÉ DE VERSAILLES SAINT-QUENTIN-EN-YVELINES'}/>
                    <WidgetClassic titleWidget={'Première inscription université'}
                                   dataTable={widgetPremiereInscription}/>
                </div>

            </div>
        </div>
    );
}
